import { qspost } from '@/config/axios';

//获取列表数据
export const getList = data => qspost("request?",data,"getUser");

//冻结*解冻
export const freezeThaw = data => qspost("request?",data,"updateStatus");

//重置密码
export const reset = data => qspost("request?",data,"resetPassword");

//获取落地页地址
export const getCopy = data => qspost("request?",data,"getLanding");

//获取vip类型
export const setVip = data => qspost("request?",data,"setVip");

//添加用户vip时间
export const selectVip = data => qspost("request?",data,"selectVip");

// 提款
export const peopleWithdraw = data => qspost("request?",data,"peopleWithdraw");

// 充值
export const peopleRecharge = data => qspost("request?",data,"peopleRecharge");

// 清除打码
export const resetDama = data => qspost("request?",data,"resetDama");

// 修改银行卡
export const updateBankInfo = data => qspost("request?",data,"updateBankInfo");

// 清除银行卡
export const delBankInfo = data => qspost("request?",data,"delBankInfo");
export const add = data => qspost("request?",data,"userAdd");
export const addMulti = data => qspost("request?",data,"userAddMulti");
export const updateUserAvatar = data => qspost("request?",data,"updateUserAvatar");