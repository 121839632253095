<template>
  <div id="bg">
    <div class="search">
      <div class="search-box">
        <span class="search-box-title">账号：</span>
        <el-input v-model="search.account" placeholder="请输入账号" size="medium" clearable></el-input>
      </div>
      <div class="search-box">
        <span class="search-box-title">昵称：</span>
        <el-input v-model="search.nickname" placeholder="请输入昵称" size="medium" clearable></el-input>
      </div>
      <div class="search-box">
        <span class="search-box-title">精准：</span>
        <el-checkbox v-model="search.checked" :true-label="1" :false-label="0" size="medium"></el-checkbox>
      </div>
      <div class="search-box">
        <span class="search-box-title">IP：</span>
        <el-input v-model="search.ip" placeholder="请输入IP" size="medium" clearable></el-input>
      </div>
      <div class="search-box">
        <span class="search-box-title">上级账号：</span>
        <el-input v-model="search.agent_account" placeholder="请输入上级账号" size="medium" clearable></el-input>
      </div>
      <div class="search-box">
        <span class="search-box-title">状态：</span>
        <el-select v-model="search.online" placeholder="全部" size="medium" clearable>
          <el-option label="全部" :value=-1 ></el-option>
          <el-option label="离线" :value=0 ></el-option>
          <el-option label="在线" :value=1 ></el-option>
        </el-select>
      </div>
      <div class="search-box">
        <span class="search-box-title">VIP：</span>
        <el-select v-model="search.vip" filterable placeholder="全部" size="medium" clearable>
          <el-option v-for="item in search.vipArr" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </div>
      <div class="search-box">
        <el-button type="primary" size="medium" @click="getList()">查询</el-button>
      </div>
      <div class="search-box">
        <el-button type="primary" size="medium" @click="dialogAddUser=true;addUser={username:'',password:'',nickname:''}">添加用户</el-button>
        <el-button type="primary" size="medium" @click="dialogAddUserMulti=true;addUserMulti={number:0}">批量添加用户</el-button>
      </div>
    </div>
    <el-table :data="tableData" :header-cell-style="{ background: '#F7F8FA' }">
      <el-table-column prop="uid" label="ID" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="gd_id" label="渠道" align="center" :show-overflow-tooltip="true">
      </el-table-column>
      <el-table-column prop="avatar" label="头像" align="center" :show-overflow-tooltip="true">
          <template #default="scope">
            <img :src="getImage(scope.row.avatar)" @click="changeAvatar(scope.row)" width="30" height="30" title="点击更换头像">
          </template>
      </el-table-column>
      <el-table-column prop="account" label="账号" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="vip_expire" label="VIP到期时间" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="nickname" label="昵称" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="agent_account" label="上级账号" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="reg_time" label="注册时间" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="ip" label="当前IP" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="uptime" label="最近活跃" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="version" label="版本" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="fee_watch_counter" label="今日剩余观影次数" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="fee_down_counter" label="今日剩余下载次数" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="status" label="状态" align="center" :show-overflow-tooltip="true">
        <template #default="scope">
          <div :style="{color: scope.row.status == '冻结' ? '#F33333' : scope.row.status == '离线' ? '#666' : '#33C933' }">{{scope.row.status}}</div>
        </template>
      </el-table-column>
      <el-table-column prop="seconds" label="今日在线" align="center" :show-overflow-tooltip="true">
        <template #default="scope">
          <span>{{getOnline(scope.row.seconds)}}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="250px" align="center">
        <template #default="scope">
          <span class="operation" @click="handle(scope.row)">操作</span>
          <span class="operation" @click="copy(scope.row.uid)">复制分享链接</span>
        </template>
      </el-table-column>
    </el-table>
    <page :pager="pager" @query="getList()" />

    <!-- 添加用户 -->
    <el-dialog title="添加用户" v-model="dialogAddUser" width="492px" top="10vh" :close-on-click-modal="false">
      <div class="form_box">
        <div class="form_title">账号：</div>
        <el-input v-model="addUser.username" placeholder="账号" size="small" ></el-input>
      </div>
      <div class="form_box">
        <div class="form_title">密码：</div>
        <el-input v-model="addUser.password" placeholder="密码" size="small"></el-input>
      </div>
      <div class="form_box">
        <div class="form_title">昵称：</div>
        <el-input v-model="addUser.nickname" placeholder="昵称" size="small"></el-input>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="dialogAddUser=false">取消</el-button>
          <el-button type="primary" @click="add">添加</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 批量添加用户 -->
    <el-dialog v-loading.fullscreen.lock="loading" title="批量添加用户" v-model="dialogAddUserMulti" width="492px" top="10vh" :close-on-click-modal="false">
      <div class="form_box">
        用于官方短视频上传，昵称随机,头像随机
      </div>
      <div class="form_box">
        <div class="form_title">数量：</div>
        <el-input v-model="addUserMulti.number" placeholder="请输入用户数量!" size="small" ></el-input>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="dialogAddUserMulti=false">取消</el-button>
          <el-button type="primary" @click="addMulti">添加</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 重置密码 -->
    <el-dialog custom-class="dialog" title="重置密码" v-model="dialogReset" width="492px" top="35vh" :close-on-click-modal="false" @close="closeDialog">
      <div class="form_box">
        <div class="form_title">密码：</div>
        <el-input v-model="resetArr.password" placeholder="密码" size="small" show-password></el-input>
      </div>
      <div class="form_box">
        <div class="form_title">确认密码：</div>
        <el-input v-model="resetArr.confirm" placeholder="确认密码" size="small" show-password></el-input>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="reset">确认重置</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 添加vip时长 -->
    <el-dialog custom-class="dialog" :title="vipTitle" v-model="dialogVip" width="492px" top="35vh" :close-on-click-modal="false" @close="closeDialog">
      <div class="search-box">
        <span class="search-box-title">VIP：</span>
        <el-select v-model="vip_type" filterable placeholder="未选择" size="medium" clearable>
          <el-option :key="0" label="非会员" :value="0"></el-option>
          <el-option v-for="item in vipTypeArr" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="setVip">确认添加</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 充值 -->
    <el-dialog custom-class="dialog" :title="peopleRechargeTitle" v-model="dialogPeopleRecharge" width="492px" top="35vh" :close-on-click-modal="false"
      @close="closeDialog">
      <div class="form_box">
        <div class="form_title">充值金额：</div>
        <el-input v-model="price" placeholder="充值金额" size="small" @change="dama = price"></el-input>
      </div>
      <div class="form_box">
        <div class="form_title">打码：</div>
        <el-input v-model="dama" placeholder="打码" size="small"></el-input>
      </div>
      <div class="form_box">
        <div class="form_title">谷歌验证码：</div>
        <el-input v-model="google_code" placeholder="谷歌验证码" size="small"></el-input>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="peopleRecharge">确认充值</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 取款 -->
    <el-dialog custom-class="dialog" :title="peopleWithdrawTitle" v-model="dialogPeopleWithdraw" width="492px" top="35vh" :close-on-click-modal="false"
      @close="closeDialog">
      <div class="form_box">
        <div class="form_title">取款金额：</div>
        <el-input v-model="price" placeholder="取款金额" size="small"></el-input>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="peopleWithdraw">确认取款</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 银行卡管理 -->
    <el-dialog custom-class="dialog" title="银行卡管理" v-model="dialogBankCardManagement" width="812px" top="35vh" :close-on-click-modal="false"
      @close="closeDialog">
      <div class="form_box">
        <table border="1" cellspacing="0" border-collapse="collapse">
          <tr>
            <td class="tbTitle">用户ID</td>
            <td class="tbContent">{{BankInfoArr.uid}}</td>
            <td class="tbTitle">银行卡号</td>
            <td class="tbContent">
              <el-input v-model="BankInfoArr.bank_id" placeholder="银行卡号"></el-input>
            </td>
          </tr>
          <tr>
            <td class="tbTitle">真实姓名</td>
            <td class="tbContent">{{BankInfoArr.real_name}}</td>
            <td class="tbTitle">银行卡类型</td>
            <td class="tbContent">
              <el-select v-model="BankInfoArr.bank_type" filterable placeholder="未选择" size="medium" clearable>
                <el-option v-for="item in bankArr" :key="item.id" :label="item.bank_name" :value="item.id"></el-option>
              </el-select>
            </td>
          </tr>
          <tr>
            <td class="tbTitle">开户行地址</td>
            <td class="tbContent" colspan="3">
              <el-input v-model="BankInfoArr.address" placeholder="开户行地址"></el-input>
            </td>
          </tr>
        </table>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="updateBankInfo">确定修改</el-button>
          <el-button type="primary" @click="delBankInfo(row)">清除银行卡信息</el-button>
        </span>
      </template>
    </el-dialog>

    <el-dialog custom-class="dialog" title="设置头衔" v-model="dialogUserTitleVisible" width="400px" top="5vh" :close-on-click-modal="false"
               @close="closeDialog">
      <el-row>
        <el-col :span="6" align="right">账号:</el-col>
        <el-col :span="18" align="left"><el-input v-model="row.account" disabled></el-input></el-col>
      </el-row>
      <el-row>
        <el-col :span="6" align="right">头衔名称:</el-col>
        <el-col :span="18" align="left"><el-input placeholder="请输入头衔名称" v-model="user_title.title"></el-input></el-col>
      </el-row>
      <el-row>
        <el-col :span="6" align="right">字体颜色:</el-col>
        <el-col :span="4" align="left"><el-color-picker v-model="user_title.front_bg"></el-color-picker></el-col>
        <el-col :span="14" align="left"><el-input placeholder="请输入字体颜色" v-model="user_title.front_bg"></el-input></el-col>
      </el-row>
      <el-row>
        <el-col :span="6" align="right">背景色:</el-col>
        <el-col :span="4" align="left"><el-color-picker v-model="user_title.back_bg"></el-color-picker></el-col>
        <el-col :span="14" align="left"><el-input placeholder="请选择背景色" v-model="user_title.back_bg"></el-input></el-col>
      </el-row>
      <el-row>
        <el-col :span="6" align="right">预览:</el-col>
        <el-col :span="18" align="left">
          <span style="display: inline-block;width: 100px; height: 30px; line-height: 30px; text-align: center; padding: 3px 5px; border-radius: 5px;" :style="{color:user_title.front_bg,background: user_title.back_bg}">{{user_title.title}}</span>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="4" align="right"></el-col>
        <el-col :span="20" >
          <el-button type="primary" @click="dialogUserTitleVisible=false">取消</el-button>
          <el-button type="primary" @click="saveUserTitle(row)">保存</el-button>
        </el-col>
      </el-row>

    </el-dialog>


    <!-- 操作 -->
    <el-dialog :title="handleTitle" v-model="dialogHandle" width="850px" top="10vh" :close-on-click-modal="false" @close="closeDialog">
      <div class="form_box">
        <div>
          <div class="search-box">
            <span class="search-box-title">用户账号：</span>
            <el-input v-model="username" style="width:230px" placeholder="请输入用户账号" clearable size="medium" />
          </div>
          <div>
            <el-button size="medium" :loading="loading" @click="getUserInfo()">查询</el-button>
          </div>
          <div v-if="findData.uid" class="userCard">
            <el-descriptions title="" column="1">
              <el-descriptions-item label="用户ID">{{row.uid}}</el-descriptions-item>
              <el-descriptions-item label="账号">{{row.account}}</el-descriptions-item>
              <el-descriptions-item label="上级账号">{{row.agent_account}}</el-descriptions-item>
               <el-descriptions-item label="渠道信息">{{row.gd_id}}</el-descriptions-item>
              <el-descriptions-item label="昵称">{{row.nickname}}</el-descriptions-item>
              <el-descriptions-item label="会员">
                {{row.vip_expire}}
                <el-button type="primary" class="doButton" size="small" @click="(dialogVip = true), (vipTitle = '设置会员' + row.nickname + '的VIP天数')">设置会员</el-button>
              </el-descriptions-item>
              <el-descriptions-item label="注册时间">{{row.reg_time}}</el-descriptions-item>
              <el-descriptions-item label="最近登录时间">{{row.uptime}}</el-descriptions-item>
              <el-descriptions-item label="最近登录IP">{{row.ip}}</el-descriptions-item>
              <el-descriptions-item label="余额">
                {{row.price}}
                <el-button type="primary" class="doButton" size="small" @click="(dialogPeopleRecharge = true), (peopleRechargeTitle = '充值：用户(' + row.nickname + ')')">充值</el-button>
                <el-button type="primary" size="small" @click="(dialogPeopleWithdraw = true), (peopleWithdrawTitle = '提现：用户(' + row.nickname + ')')">提现</el-button>
                <el-button type="primary" size="small" @click="setBankInfo(row)">银行卡</el-button>
              </el-descriptions-item>
              <el-descriptions-item label="剩余打码">
                {{row.dama}}
                <el-button type="primary" class="doButton" size="small" @click="resetDama(row)">清除打码</el-button>
              </el-descriptions-item>
              <el-descriptions-item label="账号状态">
                {{row.status}}
                <el-button type="primary" class="doButton" size="small" @click="freezeThaw(row, row.status == '冻结' ? '解冻' : '冻结')">{{ row.status == '冻结' ? '解冻' : '冻结' }}</el-button>
                <el-button type="primary" size="small" @click="dialogReset = true">重置密码</el-button>
              </el-descriptions-item>
              <el-descriptions-item label="头衔">
                {{row.user_title.title ? row.user_title.title : "无"}}
                <el-button type="primary" class="doButton" size="small" @click="showUserTitle(row)">设置头衔</el-button>
              </el-descriptions-item>
            </el-descriptions>
            <!-- <el-divider></el-divider>
            <el-descriptions title="游戏信息" column="1">
              <el-descriptions-item label="总上分金额">{{row.price}}</el-descriptions-item>
              <el-descriptions-item label="总下分金额">{{row.price}}</el-descriptions-item>
              <el-descriptions-item label="总流水金额">{{row.price}}</el-descriptions-item>
              <el-descriptions-item label="总输赢金额">{{row.price}}</el-descriptions-item>
              <el-descriptions-item label="总充值金额">{{row.price}}</el-descriptions-item>
              <el-descriptions-item label="总提现金额">{{findData.price}} (仅计算提现成功)</el-descriptions-item>
            </el-descriptions> -->
          </div>
          <div v-else class="userCard">
            <p>加载失败！此用户不存在!</p>
          </div>
        </div>
        <!-- <table border="1" cellspacing="0" border-collapse="collapse">
          <tr>
            <td class="tbTitle">用户ID</td>
            <td class="tbContent">{{row.uid}}</td>
            <td class="tbTitle">VIP到期时间</td>
            <td class="tbContent">{{row.vip_expire}}</td>
          </tr>
          <tr>
            <td class="tbTitle">昵称</td>
            <td class="tbContent">{{row.nickname}}</td>
            <td class="tbTitle">余额</td>
            <td class="tbContent">{{row.price}}</td>
          </tr>
          <tr>
            <td class="tbTitle">真实姓名</td>
            <td class="tbContent">{{row.real_name}}</td>
            <td class="tbTitle">剩余打码</td>
            <td class="tbContent">{{row.dama}}</td>
          </tr>
          <tr>
            <td class="tbTitle">注册时间</td>
            <td class="tbContent">{{row.reg_time}}</td>
            <td class="tbTitle">手机号</td>
            <td class="tbContent">{{row.phone}}</td>
          </tr>
          <tr>
            <td class="tbTitle">状态</td>
            <td class="tbContent">{{row.status}}</td>
            <td class="tbTitle">上级账号</td>
            <td class="tbContent">{{row.agent_account}}</td>
          </tr>
          <tr>
            <td class="tbTitle">今日观影次数</td>
            <td class="tbContent">{{row.today_watch}}</td>
            <td class="tbTitle">今日下载次数</td>
            <td class="tbContent">{{row.today_down}}</td>
          </tr>
          <tr>
            <td class="tbTitle">头衔</td>
            <td class="tbContent"><span style="display: inline-block;width: 100px; height: 30px; line-height: 30px; text-align: center; padding: 3px 5px; border-radius: 5px;" :style="{color:row.user_title.front_bg,background: row.user_title.back_bg}">{{row.user_title.title}}</span>
            </td>
            <td class="tbTitle"></td>
            <td class="tbContent"></td>
          </tr>
        </table> -->
      </div>
      <!-- <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="freezeThaw(row, row.status == '冻结' ? '解冻' : '冻结')">{{ row.status == '冻结' ? '解冻' : '冻结' }}</el-button>
          <el-button type="primary" @click="dialogReset = true">重置密码</el-button>
          <el-button type="primary" @click="(dialogPeopleRecharge = true), (peopleRechargeTitle = '充值：用户(' + row.nickname + ')')">充值</el-button>
          <el-button type="primary" @click="(dialogVip = true), (vipTitle = '设置会员' + row.nickname + '的VIP天数')">设置会员</el-button>
          <el-button type="primary" @click="resetDama(row)">清除打码</el-button>
          <el-button type="primary" @click="(dialogPeopleWithdraw = true), (peopleWithdrawTitle = '提现：用户(' + row.nickname + ')')">提现</el-button>
          <el-button type="primary" @click="setBankInfo(row)">银行卡</el-button>
          <el-button type="primary" @click="showUserTitle(row)">设置头衔</el-button>
        </span>
      </template> -->
    </el-dialog>
    <input type="file" id="avatar-file" @change="uploadUserAvatar" ref="avatarfile" style="display: none" accept="image/png, image/jpeg, image/jpg"/>
  </div>
</template>

<script>
import {
  getList,
  freezeThaw,
  reset,
  getCopy,
  setVip,
  resetDama,
  delBankInfo,
  peopleWithdraw,
  peopleRecharge,
  selectVip,
  updateBankInfo,
  add,addMulti,updateUserAvatar
} from "@/api/users/list";
import { bankList } from "@/api/system/cardType";
import page from "@/components/page";
import axios from "axios";
import httpClient from "@/config/httpClient";
export default {
  name: "userList",
  components: {
    page
  },
  data() {
    return {
      loading: false,
      dialogUserTitleVisible:false,
      dialogAddUser:false,
      dialogAddUserMulti:false,
      addUser:{
        username:"",
        password:"",
        nickname:"",
      },
      addUserMulti:{
        number:0,
      },
      pager: { total: 0, page: 1, rows: 10 },
      search: {
        //搜索
        account: "", //账号
        ip: "", //ip
        agent_account: "", //上级账号
        online: -1, //上级账号
        nickname: "", //昵称
        vip: 0, //vip选择
        checked: 1, //是否精确搜索
        vipArr: [
          { label: "全部", value: 0 },
          { label: "无/已过期", value: 1 },
          { label: "未过期", value: 2 },
          { label: "永久", value: 3 },
        ], //vip数组
      },
      dialogReset: false, //重置密码弹出层
      resetArr: {
        //重置密码
        password: "",
        confirm: "",
      },
      tableData: [], //数据
      row: [], //选中的数据
      user_title:{
        front_bg:"#FEFAFA",// 字体颜色
        title:"",
        back_bg:"", // 背景颜色
      },
      user_title_css:"",
      dialogVip: false, // 添加VIP时长弹出层
      vipTitle: "", //
      vip_type: "", // 添加VIP时长时间
      dialogHandle: false, // 操作弹出层
      handleTitle: "用户数据概览",
      username: "", // 操作搜索账号
      findData: {}, // 根据账号查询指定用户数据
      dialogPeopleRecharge: false, // 充值弹出层
      peopleRechargeTitle: "", // 充值标题
      price: 0, // 金额
      dama: 0, // 打码
      google_code: "", // 打码
      dialogPeopleWithdraw: false, // 取款弹出层
      peopleWithdrawTitle: "", // 取款标题
      vipTypeArr: [], // vip类型
      dialogBankCardManagement: false, // 银行卡管理弹出层
      bankArr: {}, // 银行卡类型数组
      BankInfoArr: {
        uid: "",
        bank_id: "",
        real_name: "",
        bank_type: "",
        address: "",
      },
      avatarFile:null,
      avatarRow:null,
    };
  },
  watch:{
    "user_title.title":{handler:"changeUserTitle"}
  },
  created() {},
  mounted() {
    this.getList();
    this.getCopy();
    this.selectVip();
    this.bankList();
  },
  methods: {
    getOnline(seconds){
      if (seconds == 0){
        return 0
      }
      var h = Math.floor(seconds / 3600)
      var m = Math.floor((seconds - h * 3600) / 60)
      var s = seconds - h * 3600 - m * 60
      var title = ""
      if (h > 0){
        title += h+"小时"
      }
      if(m > 0){
        title += m+"分钟"
      }
      title += s+"秒"
      return title
    },
    getUserInfo(){
      if(this.username == ""){
        return this.$message({ message: "搜索账号不能为空", type: "error" });
      }
      this.loading = true;
      getList([
        { key: "page", val: 1 },
        { key: "row", val: 1 },
        { key: "account", val: this.username },
        // { key: "ip", val: this.search.ip },
        // { key: "agent_account", val: this.search.agent_account },
        // { key: "nickname", val: this.search.nickname },
        { key: "vip", val: 0 },
        { key: "checked", val: 1 },
      ])
        .then((res) => {
          if (res.code == 0) {
            if(res.data.list.length > 0){
              for(var i in res.data.list){
                try{
                  res.data.list[i].user_title = JSON.parse(res.data.list[i].user_title)
                }catch (e){
                  res.data.list[i].user_title = {
                    front_bg:"",
                    title:"",
                    back_bg:"",
                  }
                }
              }
              this.findData = res.data.list[0];
              this.row = this.findData;
              this.loading = false;
            }else{
              this.findData = {};
            }
          } else {
            this.$message({ message: res.data, type: "error" });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    showUserTitle(row){
      console.log(row)
      this.dialogUserTitleVisible = true
      this.user_title.title = row.user_title.title
      this.user_title.front_bg = row.user_title.front_bg
      this.user_title.back_bg = row.user_title.back_bg
      // "{"title": "我日你吗啊", "back_bg": "#F2D0D0", "front_bg": "#070707"}"
    },
    saveUserTitle(row){
      console.log(row)
      console.log(this.user_title)
      httpClient("setUserTitle").post({
        title:this.user_title.title,
        front_bg:this.user_title.front_bg,
        back_bg:this.user_title.back_bg,
        user_id:row.uid,
      }).then((res)=>{
        if (res.code == 0){
          this.dialogUserTitleVisible = false;
          row.user_title ={
            title:this.user_title.title,
            front_bg:this.user_title.front_bg,
            back_bg:this.user_title.back_bg,
          }
          this.$message({message:"设置成功!",type:"success"})
        }else{
          this.$message({message:res.msg,type:"error"})
        }
      },(err)=>{
        console.log(err)
      })
    },
    getImage(src){
      return this.$store.getImage(src)
    },
    changeUserTitle(){
      if (this.user_title.title.length > 5){
        this.$message({message:"头衔最多5个字!",type:"error"})
        this.user_title.title = this.user_title.title.substring(0,5)
      }
    },
    changeAvatar(row){
      // 点击更换头像
      this.avatarRow = row; // 当前的行
      document.getElementById("avatar-file").click()
    },
    updateUserAvatar(filename){
      this.$common.showLoading("正在保存用户头像!")
      updateUserAvatar([
        {key:"id",val:this.avatarRow.uid},
        {key:"avatar",val:filename}
      ]).then((res)=>{
        this.$common.hideLoading()
        if(res.code == 0){
          this.avatarRow.avatar = filename
        }else{
          this.$message({ message: "上传失败:"+res.msg, type: "error" });
        }
      },(err)=>{
        console.log(err)
        this.$common.hideLoading()
        this.$message({ message: "上传失败:"+err, type: "error" });
      })
    },
    compressImg(file,callback){
      var img = document.createElement("img")
      img.width = 120
      img.height = 120
      img.src = URL.createObjectURL(file)
      setTimeout(()=>{
        var canvas = document.createElement("canvas")
        canvas.width = img.width
        canvas.height = img.height
        canvas.getContext('2d').drawImage(img,0,0,canvas.width,canvas.height)
        callback(this.base64ToFile(canvas.toDataURL("image/png"),(new Date()).toTimeString() + ".png"))
      },200)
    },
    base64ToFile(urldata,filename){
      let arr = urldata.split(",");
      let mine = arr[0].match(/:(.*?);/)[1];
      let bytes = atob(arr[1]);// 解码base64
      let n = bytes.length
      let ia = new Uint8Array(n);
      while (n--){
        ia[n] = bytes.charCodeAt(n);
      }
      return new File([ia],filename,{type:mine})
    },
    uploadUserAvatar(){
      // 开始上传
      this.$common.showLoading("正在上传图片:0%")
      // this.$refs.avatarfile.files[0]
      this.compressImg(this.$refs.avatarfile.files[0],(file)=>{
        this.postFile(file,(e)=>{
          let percent = e.loaded / e.total * 100;
          this.$common.showLoading("正在上传图片:"+Number(percent >= 99?99:percent).toFixed(2)+"%")
        },60).then((res)=>{
          if(res.code == 0 ){
            this.updateUserAvatar(res.data.filename)
          }else{
            this.$common.hideLoading()
            this.$message({ message: "上传失败:"+res.msg, type: "error" });
          }
        },(err)=>{
          this.$common.hideLoading()
          this.$message({ message: "上传失败:"+err, type: "error" });
        })
      })
    },
    postFile(file,progress,_timeout) {
      let timeout = 60;
      if (_timeout !== undefined){
        timeout = _timeout
      }
      var _headers = {
        "Content-Type":'multipart/form-data;charset-utf-8',
        "Name":"avatar",
        "Token":localStorage.getItem("token"),
        "Uid":localStorage.getItem("uid"),
      }
      axios.defaults.timeout = 1000 * timeout;
      return new Promise((resolve, reject) => {
        let data = new FormData();
        let upload_url = localStorage.getItem("uploader_api"); // 视频上传地址
        data.append('uploadfile',file)
        data.append("ac","uploadFile")
        axios.post(upload_url+"/uploadFile", data,{
          headers:_headers,
          onUploadProgress:(event)=>{
            if (progress !== undefined && typeof progress === "function"){
              progress(event)
            }
          },
        }).then(res => {
          resolve(res.data);
        }).catch(err => {
          console.log(err)
          reject(err)
        })
      });
    },
    addMulti(){
      if (this.addUserMulti.number <= 0 ){
        this.$message({ message: "请输入要添加的用户数量!", type: "error" });
        return;
      }
      // 批量添加用户
      this.$common.showLoading("正在批量添加用户")
      addMulti([{key:"number",val:this.addUserMulti.number}]).then((res)=>{
        this.$common.hideLoading()
        if(res.code == 0){
          this.$message({ message: "添加成功", type: "success" });
          this.dialogAddUserMulti = false
          this.getList()
        }else{
          this.$message({ message: res.msg, type: "error" });
        }
      },(err)=>{
        console.log(err)
        this.$common.hideLoading()
        this.$message({ message: err, type: "error" });
      })
    },
    add(){
      if (this.addUser.username == ""){
        this.$message({ message: "请输入账号", type: "error" });
        return
      }
      if (this.addUser.password == ""){
        this.$message({ message: "请输入密码", type: "error" });
        return
      }
      if (this.addUser.nickname == ""){
        this.$message({ message: "请输入昵称", type: "error" });
        return
      }
      var data = [];
      for(var key in this.addUser){
        data.push({
          key:key,
          val:this.addUser[key]
        })
      }
      this.$common.showLoading("添加用户中,请稍后!")
      add(data).then((res)=>{
        this.$common.hideLoading()
        if (res.code == 0){
          this.$message({ message: "添加成功", type: "success" });
          this.dialogAddUser = false;
          this.getList()
        }else{
          this.$message({ message: res.msg, type: "error" });
        }
      },(err)=>{
        console.log(err)
        this.$common.hideLoading()
      })
    },
    //[{ key:"account",val:this.account,reg:"str",title:"账号"},{ key:"password",val:this.password,reg:"str",title:"密码"}]
    //获取数据
    getList() {
      getList([
        { key: "page", val: this.pager.page },
        { key: "row", val: this.pager.rows },
        { key: "account", val: this.search.account },
        { key: "online", val: this.search.online },
        { key: "ip", val: this.search.ip },
        { key: "agent_account", val: this.search.agent_account },
        { key: "nickname", val: this.search.nickname },
        { key: "vip", val: this.search.vip || 0 },
        { key: "checked", val: this.search.checked },
      ])
        .then((res) => {
          if (res.code == 0) {
            if(this.pager.page == 1){
              this.pager.total = res.data.total;
            }
            for(var i in res.data.list){
              try{
                res.data.list[i].user_title = JSON.parse(res.data.list[i].user_title)
              }catch (e){
                res.data.list[i].user_title = {
                  front_bg:"",
                  title:"",
                  back_bg:"",
                }
              }
            }
            this.tableData = res.data.list;
          } else {
            this.$message({ message: res.data, type: "error" });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 获取银行卡类型
    bankList() {
      bankList()
        .then((res) => {
          if (res.code == 0) {
            this.bankArr = res.data;
          } else {
            this.$message({ message: res.msg, type: "error" });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 获取vip数据
    selectVip() {
      selectVip()
        .then((res) => {
          if (res.code == 0) {
            this.vipTypeArr = res.data;
          } else {
            this.$message({ message: res.data, type: "error" });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //冻结*解冻
    freezeThaw(row, str) {
      this.$confirm(
        "你确定要" + str + "用户：" + row.account + "吗？",
        "提示",
        { confirmButtonText: "确定", cancelButtonText: "取消", type: "warning" }
      )
        .then(() => {
          console.log(row.uid);
          freezeThaw([{ key: "user_id", val: row.uid }])
            .then((res) => {
              if (res.code == 0) {
                this.getList();
                this.dialogHandle = false;
                this.$message({ message: res.data, type: "success" });
              } else {
                console.log(res);
                this.$message({ message: res.msg, type: "error" });
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //重置密码
    reset() {
      if (this.resetArr.password != this.resetArr.confirm) {
        this.$message({
          message: "密码不一致",
          type: "error",
        });
        return;
      }
      reset([
        { key: "password", val: this.resetArr.password },
        { key: "user_id", val: this.row.uid },
      ])
        .then((res) => {
          if (res.code == 0) {
            this.getList();
            this.$message({ message: res.msg, type: "success" });
            this.dialogReset = false;
          } else {
            this.$message({ message: res.msg, type: "error" });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 复制
    copy(str) {
      var url = this.url + "?uid=" + str;
      var oInput = document.createElement("input");
      oInput.value = url;
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象
      document.execCommand("Copy"); // 执行浏览器复制命令
      oInput.className = "oInput";
      oInput.style.display = "none";
      this.$message({ message: "复制成功", type: "success" });
    },
    //获取落地页
    getCopy() {
      getCopy()
        .then((res) => {
          if (res.code == 0) {
            this.url = res.data.url;
          } else {
            this.$message({
              message: res.msg,
              type: "error",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    //清空重置密码内的数据
    closeDialog() {
      this.resetArr = { password: "", confirm: "" }; //清空重置数据
      this.vip_type = "";
      this.price = 0;
    },

    // 添加用户vip时长
    setVip() {
      if (!this.vip_type && this.vip_type !== 0)
        return this.$message({ message: "vip时长", type: "error" });
      setVip([
        { key: "vip_type", val: this.vip_type },
        { key: "user_id", val: this.row.uid },
      ])
        .then((res) => {
          if (res.code == 0) {
            this.getList();
            this.getUserInfo();
            this.$message({ message: res.msg, type: "success" });
            this.dialogVip = false;
          } else {
            this.$message({ message: res.msg, type: "error" });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 清除打码
    resetDama(row) {
      this.$confirm("你确定要清除用户：" + row.account + "的打码吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          resetDama([{ key: "user_id", val: row.uid }])
            .then((res) => {
              if (res.code == 0) {
                this.getList();
                this.getUserInfo();
                this.$message({ message: res.data, type: "success" });
              } else {
                console.log(res);
                this.$message({ message: res.msg, type: "error" });
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 把信息填入银行卡信息
    setBankInfo(row) {
      this.dialogBankCardManagement = true;
      this.BankInfoArr = {
        uid: row.uid,
        bank_id: row.bank_id,
        real_name: row.real_name,
        bank_type: row.bank_type,
        address: row.address,
      };
    },

    // 修改银行卡
    updateBankInfo() {
      if(!this.BankInfoArr.bank_id) return this.$message({ message:"银行卡号不能为空", type: "error" });
      if(!this.BankInfoArr.bank_type) return this.$message({ message:"银行卡类型", type: "error" });
      if(!this.BankInfoArr.address) return this.$message({ message:"开户行地址", type: "error" });
      updateBankInfo([
        { key: "user_id", val: this.BankInfoArr.uid },
        { key: "card_id", val: this.BankInfoArr.bank_id },
        { key: "card_type", val: this.BankInfoArr.bank_type },
        { key: "address", val: this.BankInfoArr.address },
      ])
        .then((res) => {
          if (res.code == 0) {
            this.getList();
            this.dialogBankCardManagement = false;
            this.dialogHandle = false;
            this.$message({ message: res.data, type: "success" });
          } else {
            console.log(res);
            this.$message({ message: res.msg, type: "error" });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 重置银行卡
    delBankInfo(row) {
      this.$confirm(
        "你确定要重置用户：" + row.account + "的银行卡吗？",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          delBankInfo([{ key: "user_id", val: row.uid }])
            .then((res) => {
              if (res.code == 0) {
                this.getList();
                this.dialogBankCardManagement = false;
                this.dialogHandle = false;
                this.$message({ message: res.data, type: "success" });
              } else {
                console.log(res);
                this.$message({ message: res.msg, type: "error" });
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 操作弹出层
    handle(row) {
      this.username = row.account;
      this.getUserInfo();
      this.dialogHandle = true;
      // this.row = row;
    },

    // 取款
    peopleWithdraw() {
      if (this.price < 0)
        return this.$message({ message: "金额不能为负数", type: "error" });
      peopleWithdraw([
        { key: "price", val: this.price },
        { key: "user_id", val: this.row.uid },
      ])
        .then((res) => {
          if (res.code == 0) {
            this.getList();
            this.$message({ message: res.msg, type: "success" });
            this.dialogPeopleWithdraw = false;
            this.dialogHandle = false;
          } else {
            this.$message({ message: res.msg, type: "error" });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 充值
    peopleRecharge() {
      if (this.price < 0)
        return this.$message({ message: "金额不能为负数", type: "error" });
      if (this.dama < 0)
        return this.$message({ message: "打码不能为负数", type: "error" });
      peopleRecharge([
        { key: "price", val: this.price },
        { key: "user_id", val: this.row.uid },
        { key: "dama", val: this.dama },
        { key: "google_code", val: this.google_code },
      ])
        .then((res) => {
          if (res.code == 0) {
            this.getList();
            this.$message({ message: res.msg, type: "success" });
            this.dialogPeopleRecharge = false;
            this.dialogHandle = false;
          } else {
            this.$message({ message: res.msg, type: "error" });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style scoped>
.doButton{
  margin-left: 20px;
}
.userCard{
  margin-top: 30px;
  margin-bottom: 30px;
}
.el-row{
  margin-bottom: 5px;
}
.el-row .el-col{
  padding-right: 5px;
}
.form_box {
  overflow: hidden;
  margin-bottom: 18px;
}
.form_title {
  width: 30%;
  float: left;
  height: 32px;
  line-height: 32px;
}
.form_box >>> .el-input,
.form_box >>> .el-textarea {
  width: 60%;
}
.form_box >>> .el-select .el-input {
  width: 100%;
}
.tbTitle {
  width: 100px;
  padding: 10px;
}
.tbContent {
  width: 230px;
  padding: 10px;
}
.form_box >>> .tbContent .el-input {
  width: 100%;
}
</style>
